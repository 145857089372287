<template>
  <div class="" v-resize="resize">
    <button
      class="slick-prev slick-arrow ml-3"
      type="button"
      @click="sliderLeft"
      :id="`logos-slider-arrow-left-${name}`"
      v-if="this.len > 0"
    >Previous</button>
    <ul v-if="items && items.length > 0"
      class="reset wet-logos-slider-list row slide-row justify-content-between mx-auto flex-nowrap"
      ref="row"
    >
      <li
        class="col-lg-2 col-md-3 col-sm-4 col-6 text-center"
        v-for="(item, index) in items"
        :key="index"
        ref="slide"
      >
        <a v-if="item[linkIndexName]" :href="item[linkIndexName]" target="_blank" rel="noreferrer">
          <reactive-picture
            :image="item[imageIndexName]"
            :width=100
            :height=30
            :size-collection-type="wpImageSizeCollectionType.LOGO"
          />
        </a>
        <reactive-picture
          v-else
          :image="item[imageIndexName]"
          :width=100
          :height=30
          :size-collection-type="wpImageSizeCollectionType.LOGO"
        />
      </li>
    </ul>
    <button
      class="slick-next slick-arrow mr-3"
      type="button"
      @click="sliderRight"
      :id="`logos-slider-arrow-right-${name}`"
      v-if="this.len > 0"
    >Next</button>
  </div>
</template>
<script>
import common from '@/mixins/common';
import LogService from '@/services/LogService';
import wpImageSizeCollectionType from '@/enums/wpImageSizeCollectionType';

export default {
  mixins: [common],
  props: {
    items: {
      type: Array,
      default: () => [
        {
          link: '',
          image: {},
        },
        {
          link: '',
          image: {},
        },
        {
          link: '',
          image: {},
        },
      ],
    },
    imageIndexName: {
      type: String,
      default: 'image',
    },
    linkIndexName: {
      type: String,
      default: 'link',
    },
    name: String,
  },
  components: {
    reactivePicture: () => import(/* webpackChunkName: "wp-picture" */ '@/components/ui/reactive-pictureUi.vue'),
  },
  data() {
    return {
      wpImageSizeCollectionType,
      current: 0,
      len: 0,
    };
  },
  watch: {
    current() {
      // update X scroll on .slide-row
      if (document) {
        const { row, slide } = this.$refs;
        if (row && slide) {
          row.scrollTo({
            left: slide[0].offsetWidth * this.current,
            behavior: 'smooth',
          });
        }
      }
    },
    items: {
      immediate: true,
      handler() {
        if (this.items && this.items.length > 0) {
          LogService.log('logosSlider', this.items);
          this.$nextTick(() => {
            this.calcLen();
          });
        }
      },
    },
  },
  methods: {
    sliderLeft() {
      // event on slider left btn click
      if (this.current <= 0) {
        this.current = this.len >= 0 ? this.len : 0;
      } else this.current -= 1;
    },
    sliderRight() {
      // event on slider right btn click
      if (this.current < this.len) {
        this.current += 1;
      } else this.current = 0;
    },
    resize() {
      this.calcLen();
    },
    calcLen() {
      const { row, slide } = this.$refs;
      if (!row || !slide) return;
      this.len = this.items.length - Math.round(row.offsetWidth / slide[0].offsetWidth);
    },
  },
};
</script>
<style lang="scss">
  /*! slider component style */
  .slide-row {
    overflow-x: auto;
    // -webkit-overflow-scrolling: touch;
    // scroll-behavior: smooth;
    -ms-overflow-style: none;
  }
  .slide-row::-webkit-scrollbar {
    display: none;
  }
  .slick-prev,
  .slick-next {
    opacity: 0.5;
    width: 40px;
    height: 40px;
    -webkit-transition: all ease-out 0.1s;
    -o-transition: all ease-out 0.1s;
    transition: all ease-out 0.1s;
  }
  .slick-prev:before,
  .slick-next:before {
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 1;
  }
  @media only screen and (max-width: 576px) {
    .slick-prev:before,
    .slick-next:before {
      -webkit-background-size: cover;
      background-size: cover;
    }
  }
  .slick-prev:hover,
  .slick-next:hover {
    -webkit-transition: all ease-out 0.1s;
    -o-transition: all ease-out 0.1s;
    transition: all ease-out 0.1s;
    opacity: 1;
  }
  .slick-prev:focus,
  .slick-next:focus {
    outline: none;
  }
  .slick-prev:active,
  .slick-next:active {
    opacity: 0.8;
  }
  .slick-prev {
    left: 35px;
  }
  @media only screen and (max-width: 992px) {
    .slick-prev {
      left: 0;
    }
  }
  @media only screen and (max-width: 576px) {
    .slick-prev {
      left: -15px;
    }
  }
  .slick-next {
    right: 35px;
  }
  @media only screen and (max-width: 992px) {
    .slick-next {
      right: 0;
    }
  }
  @media only screen and (max-width: 576px) {
    .slick-next {
      right: -15px;
    }
  }
  .slick-next,
  .slick-prev{
    z-index: 3;
  }
  .wet-order-grow-slider .slick-next{
    right: -30px;
  }
  .wet-order-grow-slider .slick-prev{
    left: -30px;
  }
  @media screen and (max-width: 1200px){
    .wet-order-grow-slider .slick-next{
      right: 0px;
    }
    .wet-order-grow-slider .slick-prev{
      left: 0px;
    }
  }
</style>
